import Vue from "vue";
//import App from "./App.vue";

Vue.config.productionTip = false;

import Book from "./components/Book.vue";
import Login from "./components/Login.vue";
import NotFound from "./components/Login.vue";

const routes = {
  "/": Login,
  "/book": Book,
};

window.onload = function() {
  new Vue({
    el: "#app",
    data: {
      currentRoute: window.location.pathname,
    },
    computed: {
      ViewComponent() {
        return routes[this.currentRoute] || NotFound;
      },
    },
    render(h) {
      return h(this.ViewComponent);
    },
  }).$mount("#app");
};
