<template>
  <div id="app">
    <div id="wrapper">
      <div class="password-field">
        <input
          type="password"
          v-model="password"
          v-on:keyup.enter="submit"
          placeholder="Mot de passe"
        >
        <input
          class="clear"
          type="text"
          placeholder="Mot de passe"
        >
      </div>
      <div class="password-field submit-button">
        <button @click='submit'>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 481.5 481.5"
            style="enable-background:new 0 0 481.5 481.5; fill: #a1a1b6"
            xml:space="preserve"
          >
            <g>
              <g>
                <path d="M0,240.7c0,7.5,6,13.5,13.5,13.5h326.1l-69.9,69.9c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l93-93
			c5.3-5.3,5.3-13.8,0-19.1l-93-93c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l69.9,69.9h-326C6,227.2,0,233.2,0,240.7z" />
                <path d="M382.4,0H99C44.4,0,0,44.4,0,99v58.2c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V99c0-39.7,32.3-72,72-72h283.5
			c39.7,0,72,32.3,72,72v283.5c0,39.7-32.3,72-72,72H99c-39.7,0-72-32.3-72-72V325c0-7.5-6-13.5-13.5-13.5S0,317.5,0,325v57.5
			c0,54.6,44.4,99,99,99h283.5c54.6,0,99-44.4,99-99V99C481.4,44.4,437,0,382.4,0z" />
              </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div
      class="incorrect-password"
      v-if="incorrectPassword"
    ><big>🤦</big> Mot de passe incorrect</div>
  </div>
</template>

<script>
import { post } from 'axios'

export default {
  name: 'login',
  methods: {
    submit() {
      post('.netlify/functions/login', {
        password: this.password,
      })
        .then(() => {
          window.location = '/book'
        })
        .catch(() => {
          this.incorrectPassword = true
        })
    },
  },
  data() {
    return {
      password: '',
      incorrectPassword: false,
    }
  },
  mounted() {},
}
</script>

<style lang="scss">
html,
body {
  background: black !important;
}
#wrapper {
  display: flex;
}
.submit-button {
  width: 46px !important;
  margin-left: 6px;
}
.password-field {
  --c-text: #5a5a64;
  --c-text-light: #a1a1b6;
  --c-text-selection: #09abc3;
  --c-background: #fff;
  --c-background-selection: rgba(9, 171, 195, 0.15);
  --c-border: #e2e2ed;
  --c-border-hover: #d0d0db;
  --c-border-active: #09abc3;
  --c-shadow: rgba(41, 41, 86, 0.06);
  --c-shadow-active: rgba(9, 171, 195, 0.25);
  --eye-background: 0;
  --eye-offset: 3px;
  --eye-wrapper-y: 0;
  --eye-y: 0;
  --eye-x: 0;
  --eye-s: 1;
  width: 170px;
  position: relative;
  border-radius: 7px;
  background: var(--c-background);
  box-shadow: inset 0 0 0 1px var(--border, var(--c-border)),
    0px 1px 3px var(--shadow, var(--c-shadow));
  transition: box-shadow 0.25s;
  &:hover {
    --border: var(--c-border-hover);
    --eye-duration: 0.05s;
  }
  &:focus-within {
    --border: var(--c-border-active);
    --shadow: var(--c-shadow-active);
  }
  input,
  button {
    -webkit-appearance: none;
    outline: none;
    background: none;
    border: none;
    margin: 0;
  }
  input {
    display: block;
    font-family: inherit;
    font-size: 16px;
    line-height: 21px;
    height: 45px;
    color: var(--c-text);
    padding: 12px 41px 12px 16px;
    transform: translateY(var(--y, var(--default-y, 0))) translateZ(0);
    opacity: var(--o, var(--default-o, 1));
    pointer-events: var(--pe, var(--default-pe, auto));
    transition: filter 0.35s, transform 0.4s, opacity 0.25s;
    &::placeholder {
      color: var(--c-text-light);
      transition: color 0.25s;
    }
    &::selection {
      color: var(--c-text-selection);
      background: var(--c-background-selection);
    }
    &:focus,
    &:hover {
      &::placeholder {
        color: var(--c-text);
      }
    }
    &:not(.clear) {
      width: 100%;
    }
    &.clear {
      --y: var(--clear-y, 12px);
      --o: var(--clear-o, 0);
      --pe: var(--clear-pe, none);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
    }
  }
  button {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    padding: 11px;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    transform: scale(var(--s, 1));
    color: var(--c-text-light);
    transition: color 0.25s, transform 0.15s;
    &:hover {
      color: var(--c-text);
    }
    &:active {
      --s: 0.95;
    }
    svg {
      display: block;
      width: 23px;
      height: 23px;
      pointer-events: none;
      .top,
      .bottom,
      .lashes {
        fill: none;
        stroke: currentColor;
        stroke-width: 1.5px;
        stroke-linecap: round;
      }
      .lashes {
        stroke-dasharray: 3px;
        stroke-dashoffset: var(--eye-offset);
      }
      .top {
        fill: var(--c-background);
        fill-opacity: var(--eye-background);
      }
      .eye {
        fill: currentColor;
        transform-origin: 10.5px 13.5px;
        transform: translate(var(--eye-x), var(--eye-y)) scale(var(--eye-s)) translateZ(0);
        transition: transform var(--eye-duration, 0.3s);
      }
    }
  }
  &.show {
    --default-y: -12px;
    --default-o: 0;
    --default-pe: none;
    --clear-y: 0;
    --clear-o: 1;
    --clear-pe: auto;
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

// Center & dribbble
body {
  font-family: 'Poppins', Arial;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbfbfd;
  .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
      display: block;
      height: 28px;
    }
  }
  .twitter {
    position: fixed;
    display: block;
    right: 64px;
    bottom: 14px;
    svg {
      width: 32px;
      height: 32px;
      fill: #1da1f2;
    }
  }
  .incorrect-password {
    padding: 10px;
    margin: auto;
    text-align: center;
    font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
    font-size: 15px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
    color: white;
  }
}
</style>
