<template>
  <div>
    <Flipbook
      class="flipbook"
      :pages="pages"
      :startPage="pageNum"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
    >
      <div class="action-bar">
        <double-left-icon
          class="btn left"
          @click="flipToStart"
        />
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
        />
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
        />
        <span class="page-num">
          Page {{ flipbook.page }} / {{ flipbook.numPages }}
        </span>
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
        />
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
        />
        <!-- <double-right-icon
          class="btn right"
          @click="flipToEnd(pages.length - 1)"
        /> -->
        <fullscreen-icon
          class="btn right"
          @click="requestFullScreen"
        />
      </div>
    </Flipbook>
    <p class="credit">

      <span class="emoji">🇫🇷</span>
      <a
        @click="setPageFromName('30mairie')"
        href="#"
      >Mairie du X</a> <span class="emoji">🍕</span>
      <a
        @click="setPageFromName('198libertino')"
        href="#"
      >Libertino</a> <span class="emoji">🐟</span>
      <a
        @click="setPageFromName('470mercato')"
        href="#"
      >Mercato di Siracusa</a> <span class="emoji">💒</span>
      <a
        @click="setPageFromName('640borgo')"
        href="#"
      >Borgo del Carato</a> <span class="emoji">👰</span>
      <a
        @click="setPageFromName('740gettingready')"
        href="#"
      >Getting ready</a> <span class="emoji">💍</span>
      <a
        @click="setPageFromName('810ceremonie')"
        href="#"
      >Ceremonie</a> <span class="emoji">🍷</span>
      <a
        @click="setPageFromName('1120pasta')"
        href="#"
      >Dinner</a> <span class="emoji">💃</span>
      <a
        @click="setPageFromName('1220party')"
        href="#"
      >Party</a>
    </p>
  </div>
</template>

<script>
import 'vue-material-design-icons/styles.css'
import LeftIcon from 'vue-material-design-icons/ChevronLeft'
import DoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft'
import RightIcon from 'vue-material-design-icons/ChevronRight'
//import DoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight'
import FullscreenIcon from 'vue-material-design-icons/Fullscreen'
import PlusIcon from 'vue-material-design-icons/MagnifyPlus'
import MinusIcon from 'vue-material-design-icons/MagnifyMinus'
import Flipbook from 'flipbook-vue'

export default {
  name: 'book',
  components: {
    Flipbook,
    DoubleLeftIcon,
    LeftIcon,
    RightIcon,
    //DoubleRightIcon,
    FullscreenIcon,
    PlusIcon,
    MinusIcon,
  },
  data() {
    return {
      pages: [],
      pagesHiRes: [],
      hasMouse: true,
      pageNum: null,
    }
  },
  methods: {
    onFlipLeftStart: function (page) {
      console.log('flip-left-start', page)
    },
    onFlipLeftEnd: function (page) {
      window.location.hash = '#' + page
    },
    onFlipRightStart: function (page) {
      console.log('flip-right-start', page)
    },
    onFlipRightEnd: function (page) {
      window.location.hash = '#' + page
    },
    onZoomStart: function (zoom) {
      console.log('zoom-start', zoom)
    },
    onZoomEnd: function (zoom) {
      console.log('zoom-end', zoom)
    },
    setPageFromHash: function () {
      const n = parseInt(window.location.hash.slice(1), 10)
      if (isFinite(n)) {
        return (this.pageNum = n)
      }
    },
    setPageFromName: function (name) {
      this.pageNum = this.pages.indexOf(`lg/${name}.jpg`) - 1
    },
    flipToStart: () => {
      window.location.hash = '#1'
    },
    flipToEnd: page => {
      window.location.href = '/book#' + page
    },
    requestFullScreen: () => {
      var element = document.body // Make the body go full screen.

      // Supports most browsers and their versions.
      var requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen

      if (requestMethod) {
        // Native full screen.
        requestMethod.call(element)
      } else if (typeof window.ActiveXObject !== 'undefined') {
        // Older IE.
        /* global ActiveXObject */
        var wscript = new ActiveXObject('WScript.Shell')
        if (wscript !== null) {
          wscript.SendKeys('{F11}')
        }
      }
    },
  },
  mounted() {
    window.addEventListener(
      'keydown',
      (function (_this) {
        return function (ev) {
          var flipbook
          flipbook = _this.$refs.flipbook
          if (!flipbook) {
            return
          }
          if (ev.keyCode === 37 && flipbook.canFlipLeft) {
            flipbook.flipLeft()
          }
          if (ev.keyCode === 39 && flipbook.canFlipRight) {
            return flipbook.flipRight()
          }
        }
      })(this)
    )

    //this.pages = [null, 'sm/1.jpg', 'sm/2.jpg', 'sm/3.jpg', 'sm/4.jpg', 'sm/1.jpg']
    const pagesArr = require.context('/public/lg', true, /^.*\.jpg$/)
    var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    const orderedPages = pagesArr
      .keys()
      .map(p => p.slice(2))
      .sort(collator.compare)
      .map(p => `lg/${p}`)

    this.pages = [null, ...orderedPages]

    window.addEventListener('hashchange', this.setPageFromHash)
    return this.setPageFromHash()
  },
}
</script>

<style>
.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.action-bar .btn {
  font-size: 30px;
  color: #999;
  cursor: pointer;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 20px;
}
.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.btn.plus:hover {
  cursor: zoom-in;
}
.btn.minus:hover {
  cursor: zoom-out;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
  color: white;
  font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}
.flipbook .viewport {
  width: 90vw !important;
  height: calc(100vh - 70px - 40px) !important;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}
.credit {
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 0;
  text-align: center;
  color: white;
  font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}
.credit a {
  color: rgb(109, 109, 109);
}

span.emoji {
  font-size: 25px;
  vertical-align: middle;
  line-height: 2;
  margin: 0 10px;
}
</style>